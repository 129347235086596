import "./App.css";

const handleClick = async () => {
  const res = await fetch("https://leechiu-app-email.vercel.app/api/send", {
    method: "POST",
    body: JSON.stringify({
      email: "atmagat@gmail.com",
      firstName: "Alvin",
    }),
  });
  const data = await res.json();
};

function App() {
  return (
    <div className="App">
      <h1>My first React App</h1>
      <button onClick={handleClick}>Send Email</button>
    </div>
  );
}

export default App;
